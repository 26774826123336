<template>
	<div class="page-header">
		<div v-if="image" class="page-header__image" :style="bannerImageStyle">
			<img :src="emptyImage" alt="empty image" height="315px" width="100%" />
		</div>
		<div v-else style="height: 100vw" class="page-header__image"></div>
		<div v-if="showBack" class="page-header__back-button" @click="$emit('back')">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 11" xmlns:xlink="http://www.w3.org/1999/xlink" :stroke="backgroundColor">
				<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
			</svg>
		</div>
		<div class="page-header__title" v-if="title">{{ title }}</div>
	</div>
</template>
<script>
	import { isImageProcessing, preloadImage, getOptimizeImageUrl } from './OptimizationImageTools'
	import { emptyImage } from './utils'

	export default {
		props: {
			image: { type: String, default: '' },
			title: { type: String, default: '' },
			backgroundColor: { type: String, default: '' },
			showBack: { type: Boolean, default: false },
		},
		computed: {
			bannerImageStyle() {
				return `background-image:url('${this.backgroundImage}');`
			},
		},
		data() {
			return {
				backgroundImage: emptyImage,
				emptyImage: emptyImage,
			}
		},
		mounted() {
			this.preloadBackgroundImage()
		},
		methods: {
			async preloadBackgroundImage() {
				if (!isImageProcessing(this.image)) {
					this.backgroundImage = this.image
					return
				}
				const optimizeImageOptions = {
					format: 'webp',
					quality: 100,
					height: 433,
				}
				const optimizeImageSrc = await getOptimizeImageUrl(this.image, { ...optimizeImageOptions })
				preloadImage(optimizeImageSrc)
					.then(() => {
						this.backgroundImage = optimizeImageSrc
					})
					.catch(() => {
						this.backgroundImage = this.image
					})
			},
		},
		watch: {
			image() {
				this.backgroundImage = emptyImage
				this.preloadBackgroundImage()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.page-header {
		.mb-3 {
			margin-bottom: 12px !important;
		}
		.page-header__back-button {
			position: absolute;
			top: 10px;
			left: 10px;
			padding: 10px;
			background-color: #ffffff;
			border-radius: 50%;

			&:hover {
				cursor: pointer;
			}

			svg {
				min-width: 24px;
				width: 24px;
			}
		}
		.page-header__image {
			background-color: transparent;
			background-size: cover;
			background-position: center;
			background-attachment: local;
			img {
				width: 100%;
				object-position: center;
				object-fit: cover;
			}
		}
		.page-header__title {
			color: #ffffff;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: #305a48;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0) 80%);
			padding: 0 20px 20px 20px;
			font-size: 24px;
			font-weight: 500;
			box-decoration-break: clone;
			-webkit-box-decoration-break: clone;
		}
	}
</style>
