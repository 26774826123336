<template>
	<div class="pages-block px-5-mod relative text-center mt-5 mb-5" key="one-time">
		<div class="upsell">
			<strong class="text-danger text-decoration-underline text-bold d-block">ONE TIME OFFER</strong>
			<small class="d-block mb-1">
				<span>
					{{ secondBuiltInProduct.data.product.title }} for just
					{{
						formatCurrency(
							secondBuiltInProduct.data.product.price.sale_amount_available
								? secondBuiltInProduct.data.product.price.sale_amount
								: secondBuiltInProduct.data.product.price.amount,
							user.data.currency
						)
					}}
					<span v-if="secondBuiltInProduct.data.product.price.sale_amount_available"
						>(<span style="text-decoration: line-through">{{
							formatCurrency(secondBuiltInProduct.data.product.price.amount, user.data.currency)
						}}</span>
						Value)
					</span>
				</span>
			</small>
			<div class="button" :style="{ background: userPrimaryColor }">
				<i class="v-icon blink" style="color: red; background-color: transparent">
					<svg width="24" height="24" viewBox="0 0 24 24">
						<path d="M4 15V9h8V4.16L19.84 12L12 19.84V15H4z">
							<animate attributeType="XML" attributeName="fill" values="#800;#f00;#800;#800" dur="0.8s" repeatCount="indefinite" />
						</path>
					</svg>
				</i>
				<input id="upsell_check" v-model="upsellFlag" :disabled="isPageProcessing" type="checkbox" />
				<label for="upsell_check"> Yes, I'd like this One-Time-Offer! </label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			secondBuiltInProduct: { type: Object },
			user: { type: Object },
			userPrimaryColor: { type: String },
			add_upsell_to_order: { type: Boolean },
			isPageProcessing: { type: Boolean },
		},
		data() {
			return {
				upsellFlag: false,
			}
		},
		methods: {
			formatCurrency(amount, currency = 'USD', locale = 'en-US', longSymbolUs = false) {
				const minimumFractionDigits = (amount * 10) % 10 > 0 ? 2 : 0
				return (
					(longSymbolUs && currency === 'USD' ? 'US' : '') +
					Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits: 2 }).format(amount)
				)
			},
		},
		watch: {
			upsellFlag(newVal) {
				this.$emit('input', newVal)
			},
		},
	}
</script>
