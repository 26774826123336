<template>
	<div class="reviews">
		<div v-for="(review, index) in reviews" :key="index" class="item">
			<star-rating
				v-model="review.rating"
				:star-size="12"
				:increment="0.5"
				:max-rating="5"
				:show-rating="false"
				:read-only="true"
				active-color="#FEC337"
				:animate="true"
			></star-rating>
			<div class="image-star-rating d-none" :style="{ '--avg-rating': review.rating }">
				<svg
					v-for="index in Math.ceil(review.rating)"
					width="13"
					height="13"
					viewBox="0 0 13 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z"
						fill="#FEC337"
					/>
				</svg>
				<span>{{ review.rating }}</span>
			</div>
			<div class="heading">
				<image-preview v-if="review.image" :source="review.image" :width="35" :height="35" staticClass="image" alt="review image" />
				<div v-else class="image">
					<img src="https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg" class="image" alt="reviewer frame" />
				</div>
				<div class="title-rating-wrapper">
					<p>{{ review.name }}</p>
				</div>
			</div>
			<div class="review-description">
				{{ review.description }}
			</div>
		</div>
	</div>
</template>
<script>
	import ImagePreview from './ImagePreview'

	export default {
		name: 'UserReviews',
		components: {
			StarRating: () => {
				return import('vue-star-rating')
			},
			ImagePreview,
		},
		props: {
			reviews: {
				type: Array,
				default() {
					return []
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.reviews {
		list-style: none;
		white-space: nowrap;
		width: 100%;
		overflow-x: scroll;
		display: flex;
		flex-wrap: nowrap;
		-webkit-overflow-scrolling: touch;
		padding: 24px;
		align-items: flex-start;

		&::-webkit-scrollbar {
			display: none;
		}
		.vue-star-rating {
			margin-bottom: 6px;
		}
		.image-star-rating {
			svg {
				margin-right: 2px;
			}
		}
		.item {
			background-color: #f3f6fd;
			width: 85%;
			max-width: 240px;
			flex: 0 0 auto;
			white-space: normal;
			padding: 10px 15px;
			margin-right: 15px;
			.heading {
				display: flex;
				align-items: center;
				margin-bottom: 12px;

				.image {
					width: 35px;
					height: 35px;
					object-fit: cover;
					background-color: #ced6e7;
					border-radius: 50%;
					align-items: center;
					margin-right: 10px;
					display: flex;
					img {
						width: 17px;

						display: block;
						margin: auto;
					}
				}

				.title-rating-wrapper {
					display: inline-block;
					position: relative;
					max-width: 172px;
					width: 100%;
					p {
						font-size: 13px;
						line-height: 16px;
						margin-bottom: 0px;
					}
				}
			}
			.review-description {
				font-size: 15px;
				line-height: 19px;
			}
		}
	}
</style>
